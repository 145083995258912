import { useSession } from 'next-auth/react';
import Link from 'next/link';
import Head from 'next/head';
import Layout from '@/components/Layout';
import Button from '@/components/Button';

import NotFoundImage from '@/icons/404.svg';

export default function NotFound() {
  const session = useSession();

  if (session.status === 'authenticated') {
    return (
      <>
        <Head>
          <title>Tiviti: Page not found</title>
        </Head>
        <Layout>
          <div className="not-found__authenticated-page">
            <InnerContent />
          </div>
        </Layout>
      </>
    );
  }

  if (session.status === 'unauthenticated') {
    return (
      <div className="not-found__unauthenticated">
        <InnerContent />
      </div>
    );
  }

  return <div />;
}

const InnerContent = function () {
  return (
    <div className="not-found__wrapper">
      <div className="not-found__content">
        <h2 className="not-found__title">Oops. That's not good!</h2>
        <p className="not-found__desc">
          The page you're looking for can't be found.
          <br />
          Try going back or click the button below and we'll take you home.
        </p>
        <Link href="/">
          <Button>Go to homepage</Button>
        </Link>
      </div>
      <div className="not-found__image">
        <NotFoundImage />
      </div>
    </div>
  );
};
